.fade {
    animation: fade-in-keyframes 1s;
}
@keyframes fade-in-keyframes {
    from {opacity: 0}
    to {opacity: 1}
}
.button:hover{
cursor: pointer;
}
